import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  BsChevronDoubleRight,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsWhatsapp,
} from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <Container>
          <Col md={12}>
            <Row className="g-4">
              <Col md={5}>
                <div className="d-grid gap-2">
                  <h3>
                    <img width={200} src="/assets/images/logo-dunkez.png" />
                  </h3>
                  <span className="d-grid">
                    <span className="fw-semibold">Visit Us :</span> C-14 Ground
                    Floor, Sector 6, Noida U.P - 201301, India
                  </span>
                  <span className="d-flex gap-2">
                    <span className="fw-semibold">Email :</span>{" "}
                    info@sartiaglobal.com
                  </span>
                  <span className="d-flex gap-2">
                    <span className="fw-semibold">Call Us :</span> +91
                    7838469950
                  </span>
                  <span className="d-flex gap-2">
                    <span className="fw-semibold">Office :</span> +91 8882910514
                  </span>
                </div>
              </Col>
              <Col md={4}>
                <div className="d-grid gap-2">
                  <h3 className="after-line">Links</h3>
                  <Row className="g-0">
                    <Col md={6} xs={6}>
                      <div className="d-grid gap-2">
                        <Link to="/">Home</Link>
                        <Link to="/about">About</Link>
                        <Link to="/services">Service</Link>
                        <Link to="/pricing">Pricing</Link>
                        <Link to="/blogs">Blog</Link>
                        <Link to="/contact">Contact</Link>
                      </div>
                    </Col>
                    <Col md={6} xs={6}>
                      <div className="d-grid gap-2">
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        <Link to="/terms-of-service">Terms of Service</Link>
                        <Link to="/cookie-policy">Cookie Policy</Link>
                        <Link to="/returnand-refund">Return and Refund</Link>
                        <Link to="/disclaimer-for-dunkEZ">
                          Disclaimer for DunkEZ
                        </Link>
                        <Link to="/copyright-notice">Copyright Notice</Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={3}>
                <div className="d-grid gap-2">
                  <h3 className="after-line">Newsletter</h3>
                  <p className="mb-1">Send us a newsletter to get update</p>
                  <div className="d-flex w-100 rounded">
                    <input
                      id="newsletter"
                      type="email"
                      className="form-control rounded-0 border-0 bg-transparent"
                      placeholder="Email address"
                    />
                    <span className="bg-yellow-gradient pointer d-align px-3">
                      <BsChevronDoubleRight />
                    </span>
                  </div>
                  <div className="d-align mt-3 gap-2 justify-content-start">
                    <a href="#" className="text-bg-primary rounded d-align p-1">
                      <BsFacebook />
                    </a>
                    <a
                      href="#"
                      className="bg-info text-white rounded d-align p-1">
                      <BsLinkedin />
                    </a>
                    <a
                      href="#"
                      className="bg-white text-danger rounded d-align p-1">
                      <BsInstagram />
                    </a>
                    <a href="#" className="text-bg-success rounded d-align p-1">
                      <BsWhatsapp />
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
      </footer>
      <div className="text-bg-dark text-center py-2">
        <small>© dunkez, All Right Reserved. Designed By Dunkez</small>
      </div>
    </>
  );
};

export default Footer;
