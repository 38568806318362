import { lazy, Suspense } from "react";
import { CirclesWithBar } from 'react-loader-spinner'
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Layout from "./Components/Layout/Layout";
import 'react-toastify/dist/ReactToastify.css';

const Home = lazy(() => import("./Pages/Home"));
const About = lazy(() => import("./Pages/About"));
const Service = lazy(() => import("./Pages/Service"));
const Pricing = lazy(() => import("./Pages/Pricing"));
const Blog = lazy(() => import("./Pages/Blog"));
const BlogPost = lazy(() => import("./Pages/BlogPost"));
const Contact = lazy(() => import("./Pages/Contact"));
const Checkout = lazy(() => import("./Pages/Checkout"));
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy"));
const TermsofService = lazy(() => import("./Pages/TermsofService"));
const CookiePolicy = lazy(() => import("./Pages/CookiePolicy"));
const ReturnandRefund = lazy(() => import("./Pages/ReturnandRefund"));
const DisclaimerforDunkEZ = lazy(() => import("./Pages/DisclaimerforDunkEZ"));
const CopyrightNotice = lazy(() => import("./Pages/CopyrightNotice"));

const App = () => {
	const { pathname } = useLocation();
	return (
		<>
			<title>dunkEZ · {pathname.split("/")[1]
				? pathname.split("/")[1].includes("-")
					? pathname.split("/")[1].replace("-", " ")
					: pathname.split("/")[1]
				: ""}</title>
			<Suspense
				fallback={
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							height: "100vh",
						}}
					>
						<CirclesWithBar
							height="100"
							width="100"
							color="var(--primary)"
							wrapperStyle={{}}
							wrapperclassName=""
							visible={true}
							outerCircleColor=""
							innerCircleColor="var(--pink)"
							barColor="var(--yellow)"
							ariaLabel='circles-with-bar-loading'
						/>
					</div>
				}
			>
				<Routes>
					<Route element={<Layout />}>
						<Route path="/" element={<Home />} />
						<Route path="/about" element={<About />} />
						<Route path="/services" element={<Service />} />
						<Route path="/pricing" element={<Pricing />} />
						<Route path="/checkout" element={<Checkout />} />
						<Route path="/blogs" element={<Blog />} />
						<Route path="/blogPost" element={<BlogPost />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/privacy-policy" element={<PrivacyPolicy />} />
						<Route path="/terms-of-service" element={<TermsofService />} />
						<Route path="/cookie-policy" element={<CookiePolicy />} />
						<Route path="/returnand-refund" element={<ReturnandRefund />} />
						<Route path="/disclaimer-for-dunkEZ" element={<DisclaimerforDunkEZ />} />
						<Route path="/copyright-notice" element={<CopyrightNotice />} />
					</Route>
				</Routes>
			</Suspense>
			<ToastContainer />
		</>

	);
};
export default App;
